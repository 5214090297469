.AirlineOrder {
  --background: #eeeeee;
  .avatar {
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .card-footer {
    ion-item {
      --ion-background-color: #f6f6f6 !important;
    }
    background: #f6f6f6;
  }
}
.wizardHeader {
  background-color: var(--ion-color-primary);
  font-size: 13px;
  .col-disable {
    opacity: 0.5;
  }
  span {
    background: white;
    display: inline-block;
    color: var(--ion-color-primary);
    width: 16px;
    height: 16px;
    font-size: 11px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .product-card {
    padding: 4px;
    img {
      height: 64px;
      object-fit: cover;
      width: 64px;
      border-radius: 6px;
    }
  }
  h6 {
    font-size: 10px;
    margin: 0;
  }
}

ion-textarea {
  height: 100px;
}
.touchSpin ion-button {
  height: 36px !important;
  width: 36px;
}
.baggage-active {
  border: 2px solid var(--ion-color-primary);
}
