.BookingRegular{
  --background:#eeeeee;
  .tourDateChoice{
    ion-slide{
      font-size: 10px;
      border-radius: 6px;
      border: 1px solid black;
      padding: 8px 4px;
    }
  }
  span{
    font-size: 8px;
    background: orange;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute;
    margin-top: -8px;
    margin-left: 16px;
    z-index: 1;
  }
  .tourBookingRegularPackageCard{

    ion-card-content{
      div:first-child{
        padding-bottom: 8px;
        border-bottom: 1px solid #DADADA;
        margin-bottom: 8px;
      }
    }
    h5{
      margin-bottom: 8px !important;
      font-size: 12px;
    }
    h6{
      font-size: 10px;
    }

  }
  .selectDate-active{
    background-color: var(--ion-color-primary);
    border: none !important;
    ion-text{
      color:white;
    }
    display:inline-block;
    border:1px solid #555555;
    border-radius:6px;
    padding:8px 16px;
    height:48px;
    ion-text{
      font-size: 10px;
    }
  }
  .selectDate{
    display:inline-block;
    border:1px solid #555555;
    border-radius:6px;
    padding:12px 8px 0 8px;
    height:48px;
    margin-right:8px;
    ion-icon{
      font-size:10px;
    }
    ion-text{
      font-size: 10px;
      margin-bottom: -8px;
    }
  }
}
