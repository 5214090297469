a{
    text-decoration:none;
}
.center-title-toolbar{
  ion-title{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
}
