/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */
:root {
  /** primary **/
  /* --ion-color-primary: #3A92B8; */
  --ion-color-primary: #184dc9;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

:root {
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;

  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29, 161, 244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;

  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89, 86, 216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255, 255, 255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;

  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35, 182, 234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255, 255, 255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211f1f;
  --ion-color-base-rgb: 33, 31, 31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537bc;
  --ion-color-base-rgb: 149, 55, 188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  --ion-color-primary: #184dc9;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #000000;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;
}

@media only screen and (min-width: 480px) {
  ion-app {
    max-width: 420px;
    margin: 0 auto;
  }
}

/* Custom */
.btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  --box-shadow: none;
}
.btn-outline-primary {
  /* --background: none !important; */
  background: white;
  --background: white;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  border-radius: 6px;
}
.outline-medium {
  /* --background: none !important; */
  border: 1px solid var(--ion-color-medium);
}
.btn-outline-light {
  /* --background: none !important; */
  background: none;
  --background: none;
  color: var(--ion-color-light);
  border: 1px solid var(--ion-color-light);
  border-radius: 6px;
}
.gray-bg {
  --background: #f6f6f6 !important;
  background: #f6f6f6 !important;
}
.white-bg {
  background-color: white;
}

.orange-bg {
  background: #ffaa00;
}
.text-transform-none {
  text-transform: none;
}

ion-toolbar ion-title {
  font-size: 14px;
}
.ion-sub-title {
  font-size: 10px;
}

.ion-p-8 {
  padding: 8px !important;
}
.ion-pt-8 {
  padding-top: 8px;
}
.ion-pr-8 {
  padding-right: 8px;
}
.ion-pl-8 {
  padding-left: 8px;
}
.ion-pb-8 {
  padding-bottom: 8px;
}
.ion-p-16 {
  padding: 16px !important;
}
.ion-pt-16 {
  padding-top: 16px;
}
.ion-pr-16 {
  padding-right: 16px;
}
.ion-pl-16 {
  padding-left: 16px;
}
.ion-pb-16 {
  padding-bottom: 16px;
}
.ion-pb-80 {
  padding-bottom: 80px;
}
.ion-m-8 {
  margin: 8px;
}
.ion-mt-8 {
  margin-top: 8px;
}
.ion-mr-8 {
  margin-right: 8px;
}
.ion-ml-8 {
  margin-left: 8px;
}
.ion-mb-8 {
  margin-bottom: 8px;
}
.ion-m-16 {
  margin: 16px;
}
.ion-mt-16 {
  margin-top: 16px;
}
.ion-mr-16 {
  margin-right: 16px;
}
.ion-ml-16 {
  margin-left: 16px;
}
.ion-mb-16 {
  margin-bottom: 16px;
}

.bt-lightgray-1 {
  border-top: 1px solid lightgray !important;
}

.bb-lightgray-1 {
  border-bottom: 1px solid lightgray !important;
}

.pl-bold {
  font-weight: bold;
}



.ion-br-8 {
  border-radius: 8px;
}
.primary-half-background {
  background: var(--ion-color-primary);
  width: 100%;
  height: 56px;
  margin-bottom: -56px;
}

img.ofc {
  object-fit: cover;
}
img.newsThumbnail {
  width: 300px !important;
  height: 150px !important;
}

.tourProductThumbnail {
  object-fit: cover;
  height: 180px;
}
.tourProductCard ion-card-title {
  height: 32px;
}

.loadingData {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.d-block {
  display: block;
}
.fw-500 {
  font-weight: 500;
}
.no-shadow {
  box-shadow: none;
  --box-shadow: none;
}
.center-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
ion-button {
  box-shadow: none;
  --box-shadow: none;
}

.bottomDrawer {
  position: fixed;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  /* transition: all 0.1s ease; */
}
.bottomDrawerOpacity {
  position: fixed;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
}
.bottomDrawerCard {
  width: 100%;
  background: white;
  padding: 16px;
  z-index: 99999999999;
  position: fixed;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  transition: bottom 0.5s;
}
.bottomDrawerDragPad {
  height: 4px;
  background: lightgray;
  width: 15%;
  border-radius: 8px;
  margin: 0 auto;
}
.dt-slider .ant-slider-track {
  background-color: var(--ion-color-primary) !important;
}
.dt-slider .ant-slider-handle {
  border: solid 4px var(--ion-color-primary) !important;
}
.b-primary {
  border: solid 1px var(--ion-color-primary )!important;
}
.ant-full-tabs .ant-tabs-tab {
  width: 50%;
  text-align: center;
  margin: 0;
}
.payment-guide .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color : var(--ion-color-primary);
  font-weight: bold;
  font-size: 12px;
}
.payment-guide .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  color : var(--ion-color-medium);
  font-size: 16px;
}
.payment-guide .ant-collapse-content-box{
  font-size: 12px;
}
