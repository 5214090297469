.FNNPModal::part(scroll) {
  padding: 0 !important;
}
.FNNPModal {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
    font-weight: normal;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0 24px;
  }

  .ant-tabs-tabpane {
    height: 80vh;
  }
}
