.BookingSeries{
  --background:#eeeeee;
  .tourDateChoice{
    ion-slide{
      font-size: 10px;
      border-radius: 6px;
      border: 1px solid black;
      padding: 8px 4px;
    }
  }
  .tourBookingSeriesPackageCard{
    ion-card-content{
      div:first-child{
        padding-bottom: 8px;
        border-bottom: 1px solid #DADADA;
        margin-bottom: 8px;
      }
    }
    h5{
      margin-bottom: 8px !important;
      font-size: 12px;
    }
    h6{
      font-size: 10px;
    }
  }
  .selectDate-active{
    background-color: var(--ion-color-primary);
    border: none !important;
    ion-text{
      color:white;
    }
  }
}
